@import '../../../../../../../../styles/shared';

.wrapper {
  width: 100%;
  aspect-ratio: 1.66 / 1;
}

.image {
  background: center no-repeat;
  background-size: cover;
  border-radius: 12px;
  width: 100%;
  height: 100%;
}

.featured {
  @include mq($from: desktop) {
    border-radius: 20px;
  }
}

.showOnMobileOnly {
  display: block;

  @include mq($from: tablet) {
    display: none;
  }
}

@import '../../../../../../styles/shared';

.mediumCardsWrapper {
  height: 100%;
}

.smallCardsWrapper {
  margin-top: 0;

  @include mq($from: tablet) {
    margin-top: 20px;
  }
}

.largeCard,
.mediumCard,
.smallCard {
  height: 100%;
  padding-bottom: 20px;

  @include mq($from: desktop) {
    padding-bottom: 24px;
  }
}

.largeCard,
.mediumCard {
  @include mq($from: tablet) {
    padding-bottom: 0;
  }
}

.smallCard {
  @include mq($from: desktop) {
    padding-bottom: 0;
  }
}

@import '../../../../../../../../styles/shared';

.heading {
  font-family: 'Aleo', serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.default {
  // Uses above default styles
}

.small {
  @include mq($from: desktop) {
    font-size: 24px;
    line-height: 30px;
  }
}

.medium {
  @include mq($from: desktop) {
    font-size: 28px;
    line-height: 34px;
  }
}

.large {
  @include mq($from: desktop) {
    font-size: 40px;
    line-height: 44px;
  }
}
